.containerSteps {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin-bottom: 35px;
  /* background-color: #eaedfb; */
  /* min-height: 100px; */
}
.cardSteps {
  font-family: "Rubik", sans-serif;
}
.progress_bar {
  display: flex;
  counter-reset: circle 0;
  padding-left: 0px;
}
.progress_bar li {
  list-style: none;
  margin-right: 55px;
  counter-increment: circle 1;
  display: flex;
  position: relative;
  display: flex;
  justify-content: center;
  cursor: pointer;
  
}
.progress_bar li.unvisited .title{
  opacity: 0.5;
}

.progress_bar li::before {
  content: counter(circle);
  position: absolute;
  top: 30px;
  height: 40px;
  width: 40px;
  background-color: #96b3d9;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 50%;
  z-index: 10;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.5s;
}
.progress_bar li::after {
  content: "";
  position: absolute;
  height: 1px;
  width: 136px;
  background-color: #005aa4;
  top: 48px;
  right: 37px;
  cursor: pointer;
  transition: all 0.5s;
  /* opacity: 0.1; */
  /* border-top: 2px dashed #96b3d9; */
}
.progress_bar li:nth-child(1)::after {
  display: none;
}
.progress_bar li.active::before {
  background-color: green !important;
  color: #fff !important;
  opacity: 1;
}
.progress_bar li.active::after {
  background-color: #005aa4 !important;
  opacity: 1;
}

.progress_bar li.completed::before {
  content: "\2713";
  background-color: #005aa4 !important;
  color: #fff !important;
  opacity: 1;
}
.progress_bar li.completed::after {
  background-color: #005aa4 !important;
  opacity: 1;
}

.progress_bar li.finished::before {
  content: "\2713";
  background-color: green !important;
  color: #fff !important;
  opacity: 1;
}
.progress_bar li.finished::after {
  background-color: #005aa4 !important;
  opacity: 1;
}

/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");

body {
  background-color: #eee;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
}
.container {
  height: 100vh;
}

.progresses {
  display: flex;
  align-items: center;
}

.line {
  width: 120px;
  height: 6px;
  background: #63d19e;
}

.steps {
  display: flex;
  background-color: #63d19e;
  color: #fff;
  font-size: 14px;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.sideBar {
  height: 100%;
  width: 150px;
  margin-top: 10px;
}
.stepSummary {
  width: 80px;
  height: 100%;
} */
