.app-chatbot-container {
  margin: 40px 0;
  display: flex;
  justify-content: center;
  position: fixed;
  right: 40px;
  bottom: 40px;
  z-index: 9999;
  box-shadow: 5px 5px 13px rgba(91, 81, 81, 0.4);
  border-radius: 5px;
}

.app-chatbot-button {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: #446bfb;
  border: none;
  position: fixed;
  bottom: 15px;
  z-index: 9999;
  right: 40px;
}

.answerLink {
  color: rgb(69, 239, 35);

  /* text-decoration: underline; */
}
.answerLink:hover {
  color: rgb(151, 250, 132);
  text-decoration: none;
}
