.card{
    overflow: visible!important;
}
@media (max-width: 768px){
    .container{
        padding: 0!important;
    }

    .avatar-cover{
        display: flex!important;
        align-items: center!important;
        justify-content: center!important;
        margin-bottom: 1.5em!important;
    }

    .main-profile-info>div{
        flex-direction: column!important;
        align-items: start!important;
        margin-bottom: 1em!important;
    }

    .profile-location{
        flex-direction: column!important;
    }

    .location-map-line{
        margin-left: auto;
        max-width: 85%;
        width: 85%;
    }
}