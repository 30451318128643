@import "~bootstrap/scss/bootstrap";

html,
body {
  height: 100%;
}

.w-100-mobile{
  width: 90%!important;
}

table > tbody > tr > td {
  text-wrap: wrap !important;
  white-space: normal !important;
}

.detalii-grant > p {
  margin-bottom: 0;
}

/* CUSTOM */
.authentication {
  min-height: 100vh;
}

.authentication .authentication-cover {
  background-color: white;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
  // background-image: url("https://www.shutterstock.com/shutterstock/photos/2097039964/display_1500/stock-photo-aerial-view-of-the-city-center-of-timisoara-with-holiday-lights-and-the-orthodox-metropolitan-2097039964.jpg");
  // background-image: url("../public/piata-unirii.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.authentication .authentication-cover:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  inset-inline-start: 0;
  inset-inline-end: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  background-color: rgba(58, 88, 146,0.2);
}

.form-style-new{
  display: block;
    width: 100%;
    padding: 0.47rem 0.75rem;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.5;
    color: #505d69;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
}

@media (max-width: 768px){
  .login-mobile.px-5{
    padding-left: unset!important;
    padding-right: unset!important;
  }
}
