.formio-component-persistent,
.formio-component-encrypted,
a.nav-link[href="#layout"]{
    display: none!important;
}


.btn-group-xxs>.btn, .btn-xxs, .component-btn-group .component-settings-button{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}