/**
* = Helper classes
*/

// Overflows
.overflow-visible {
    overflow: visible !important;
}

.star {
    text-align: left;
    font-size: 14px;
    line-height: 26px;
    font-weight: 400;
    padding-top: 0px;
    margin-bottom: 10px;
}

.star span{
    padding: 5px 10px;
    font-size: 12px;
    color: #fff;
    font-weight: 400;
    background: #b2c322;
    border-radius: 50px;
}

.btlr-0{
    border-top-left-radius: 0 !important;
}

.btrr-0{
    border-top-right-radius: 0 !important;
}

.bblr-0{
    border-bottom-left-radius: 0 !important;
}

.bbrr-0{
    border-bottom-right-radius: 0 !important;
}

.pagina-activa{
    align-self: center;
}

.card.custom-card .card-header {
    padding: 1rem 1.25rem;
    background-color: transparent!important;
    display: flex;
    align-items: center;
    position: relative;
    flex-wrap: wrap;
    gap: 0.25rem;
}

.card.custom-card .card-header .card-title {
    position: relative;
    -webkit-margin-after: 0;
    margin-block-end: 0;
    font-size: .9375rem;
    font-weight: 700;
}

.forum-discutii {
    -webkit-margin-after: 0;
            margin-block-end: 0;
}

.forum-discutii li {
    -webkit-padding-start: 4rem;
            padding-inline-start: 4rem;
    position: relative;
    -webkit-margin-after: 1.75rem;
            margin-block-end: 1.75rem;
}

.forum-discutii li .forum-discutii-avatar {
    position: absolute;
    inset-inline-start: 0.5rem;
    inset-block-start: 0.188rem;
}

.forum-discutii li:last-child {
    -webkit-margin-after: 0;
            margin-block-end: 0;
}

.forum-discutii li:last-child::before {
    display: none;
}

.forum-discutii li::before {
    content: "";
    background-color: transparent;
    border: 1px dashed rgba(var(--dark-rgb), 0.1);
    height: 100%;
    position: absolute;
    inset-inline-start: 2.813rem;
    inset-block-start: 1.813rem;
}

.forum-discutii .profile-activity-media img {
    width: 4rem;
    height: 3rem;
    border-radius: 0.5rem;
    margin: 0.25rem;
}

.avatar {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 500;
}

.avatar.avatar-sm {
    width: 1.75rem;
    height: 1.75rem;
    line-height: 1.75rem;
    font-size: .65rem;
}

.avatar.avatar-rounded {
    border-radius: 50%;
}

.bg-primary-transparent {
    background-color: #3a58921a!important;
    color: #3a5892!important;
}

.live-icon{
    display: inline-block;
    position: relative;
    top: calc(50% - 5px);
    background-color: green;
    width: 10px;
    height: 10px;
    margin-left: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    z-index: 1;
}

.live-icon::before{
    content: "";
    display: block;
    position: absolute;
    background-color: rgba(0, 128, 0, 0.6);
    width: 100%;
    height: 100%;
    border-radius: 50%;
    -webkit-animation: live 2s ease-in-out infinite;
    animation: live 2s ease-in-out infinite;
    z-index: -1;
}

@-webkit-keyframes live {
    0% {
        transform: scale(1, 1);
    }
    100% {
        transform: scale(3.5, 3.5);
        background-color: rgba(255, 0, 0, 0);
    }
}

@keyframes live {
    0% {
        transform: scale(1, 1);
    }
    100% {
        transform: scale(3.5, 3.5);
        background-color: rgba(255, 0, 0, 0);
    }
}

// Opacity classes
@for $nr from 0 to 10 {
    .opacity-#{$nr} {
        opacity: #{$nr/10} !important;
    }
}

// Fill opacity classes
@for $nr from 0 to 10 {
    .fill-opacity-#{$nr} {
        fill-opacity: #{$nr/10} !important;
    }
}

// Z indexes
@for $nr from 0 to 10 {
    .z-#{$nr} {
        position: relative;
        z-index: #{$nr} !important;
    }
}

.w-auto {
    width: auto !important;
}

// Border widths
.bw-md {
    border-width: $border-width-md !important;
}
.bw-lg {
    border-width: $border-width-lg !important;
}
.bw-xl {
    border-width: $border-width-xl !important;
}

.border-top-left-radius-0 {
    border-top-left-radius: 0 !important;
}

.border-top-right-radius-0 {
    border-top-end-radius: 0 !important;
}

.border-bottom-left-radius-0 {
    border-bottom-start-radius: 0 !important;
}

.border-bottom-right-radius-0 {
    border-bottom-end-radius: 0 !important;
}

.rounded-xl {
    border-radius: $border-radius-xl !important;
}

@media (min-width: 991px){
    .table-md-layout-fixed{
        table-layout: fixed !important;
    }
}
