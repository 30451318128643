.modal-backdrop {
    pointer-events: none;
}

.custom-width-detail{
    width: 65%!important;
}

.forum-component .rce-mbox-status, .forum-component .rce-mbox-right-notch, .forum-component .rce-mbox-left-notch, .forum-component .rce-mbox-reply-btn-left, .forum-component .rce-mbox-reply-btn-right{
    display: none;
}

.forum-component .rce-mbox.rce-mbox-right, .forum-component .rce-mbox{
    width: 99%;
}

.forum-component .rce-container-mbox.mt-2.ps-8{
    padding-left: 0rem!important;
}

.forum-component .rce-mbox.rce-mbox-right, .forum-component .rce-mbox{
    float: unset!important;
    margin-right: 0px!important;
    margin-left: 2px!important;
}

.forum-component .rce-mbox-title{
    color: #262B40!important;
    font-weight: bold!important;
}

@media (max-width: 768px){
    .custom-width-detail{
        width: 100%!important;
    }
}